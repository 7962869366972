<script setup>
import { ref } from 'vue';
import { GlobeAltIcon} from "@heroicons/vue/24/outline";
import { useI18n } from 'vue-i18n'

const i18nLocale = useI18n();

import { settingsStore } from "@/stores/SettingsStore.js";
const settings = settingsStore();

var languagesMenu = [
	{id: "en", label: 'English'},
	{id: "enUS", label: 'US'},
	{id: "de", label: 'Deutsch'},
	{id: "fr", label: 'Français'},
];

const props = defineProps(['changeUnits']);

function changeLanguage(id) {
	//console.log(i18nLocale.locale.value);
	i18nLocale.locale.value = id;
	settings.language=id;

	console.log(props.changeUnits);

	if (!props.changeUnits) return;

	switch (id) {
		case 'en':
		case 'de':
		case 'fr':
			settings.altitudeUnits = 'meters';
			settings.speedUnits = 'kilometersPerHour';
			settings.verticalUnits = 'metersPerSecond';
			settings.distanceUnits = 'kilometers';
			break;
		case 'enUS':
			settings.altitudeUnits = 'feet';
			settings.speedUnits = 'milesPerHour';
			settings.verticalUnits = 'feetPerMinute';
			settings.distanceUnits = 'miles';
			break;
	}
}
</script>

<template>
	<div class="w-fit mx-auto p-4 flex items-start gap-2">
		
		<GlobeAltIcon class="size-5 mt-2 min-w-5"></GlobeAltIcon>

		<div class="flex flex-wrap gap-2">
			<button v-for="item in languagesMenu"
				:key="item.id"
				class="border border-lime-600 dark:border-lime-500 py-1 px-2 rounded-lg cursor-pointer"
				@click="changeLanguage(item.id)"
				:class="settings.language==item.id ? 'bg-lime-600 dark:bg-lime-500 text-white dark:text-black' : 'hover:bg-gray-200 dark:hover:bg-gray-900'">

				{{item.label}}
			</button>
		</div>

	</div>
</template>